.jssocials-share-twitter .jssocials-share-link {
  color: #00aced; }
  .jssocials-share-twitter .jssocials-share-link .jssocials-share-label {
    color: #00aced; }
  .jssocials-share-twitter .jssocials-share-link:hover, .jssocials-share-twitter .jssocials-share-link:focus, .jssocials-share-twitter .jssocials-share-link:active {
    color: #0087ba; }
    .jssocials-share-twitter .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-twitter .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-twitter .jssocials-share-link:active .jssocials-share-label {
      color: #0087ba; }

.jssocials-share-facebook .jssocials-share-link {
  color: #3b5998; }
  .jssocials-share-facebook .jssocials-share-link .jssocials-share-label {
    color: #3b5998; }
  .jssocials-share-facebook .jssocials-share-link:hover, .jssocials-share-facebook .jssocials-share-link:focus, .jssocials-share-facebook .jssocials-share-link:active {
    color: #2d4373; }
    .jssocials-share-facebook .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-facebook .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-facebook .jssocials-share-link:active .jssocials-share-label {
      color: #2d4373; }

.jssocials-share-googleplus .jssocials-share-link {
  color: #dd4b39; }
  .jssocials-share-googleplus .jssocials-share-link .jssocials-share-label {
    color: #dd4b39; }
  .jssocials-share-googleplus .jssocials-share-link:hover, .jssocials-share-googleplus .jssocials-share-link:focus, .jssocials-share-googleplus .jssocials-share-link:active {
    color: #c23321; }
    .jssocials-share-googleplus .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-googleplus .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-googleplus .jssocials-share-link:active .jssocials-share-label {
      color: #c23321; }

.jssocials-share-linkedin .jssocials-share-link {
  color: #007bb6; }
  .jssocials-share-linkedin .jssocials-share-link .jssocials-share-label {
    color: #007bb6; }
  .jssocials-share-linkedin .jssocials-share-link:hover, .jssocials-share-linkedin .jssocials-share-link:focus, .jssocials-share-linkedin .jssocials-share-link:active {
    color: #005983; }
    .jssocials-share-linkedin .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-linkedin .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-linkedin .jssocials-share-link:active .jssocials-share-label {
      color: #005983; }

.jssocials-share-pinterest .jssocials-share-link {
  color: #cb2027; }
  .jssocials-share-pinterest .jssocials-share-link .jssocials-share-label {
    color: #cb2027; }
  .jssocials-share-pinterest .jssocials-share-link:hover, .jssocials-share-pinterest .jssocials-share-link:focus, .jssocials-share-pinterest .jssocials-share-link:active {
    color: #9f191f; }
    .jssocials-share-pinterest .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-pinterest .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-pinterest .jssocials-share-link:active .jssocials-share-label {
      color: #9f191f; }

.jssocials-share-email .jssocials-share-link {
  color: #3490F3; }
  .jssocials-share-email .jssocials-share-link .jssocials-share-label {
    color: #3490F3; }
  .jssocials-share-email .jssocials-share-link:hover, .jssocials-share-email .jssocials-share-link:focus, .jssocials-share-email .jssocials-share-link:active {
    color: #0e76e6; }
    .jssocials-share-email .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-email .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-email .jssocials-share-link:active .jssocials-share-label {
      color: #0e76e6; }

.jssocials-share-stumbleupon .jssocials-share-link {
  color: #eb4823; }
  .jssocials-share-stumbleupon .jssocials-share-link .jssocials-share-label {
    color: #eb4823; }
  .jssocials-share-stumbleupon .jssocials-share-link:hover, .jssocials-share-stumbleupon .jssocials-share-link:focus, .jssocials-share-stumbleupon .jssocials-share-link:active {
    color: #c93412; }
    .jssocials-share-stumbleupon .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-stumbleupon .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-stumbleupon .jssocials-share-link:active .jssocials-share-label {
      color: #c93412; }

.jssocials-share-whatsapp .jssocials-share-link {
  color: #29a628; }
  .jssocials-share-whatsapp .jssocials-share-link .jssocials-share-label {
    color: #29a628; }
  .jssocials-share-whatsapp .jssocials-share-link:hover, .jssocials-share-whatsapp .jssocials-share-link:focus, .jssocials-share-whatsapp .jssocials-share-link:active {
    color: #1f7d1e; }
    .jssocials-share-whatsapp .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-whatsapp .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-whatsapp .jssocials-share-link:active .jssocials-share-label {
      color: #1f7d1e; }

.jssocials-share-telegram .jssocials-share-link {
  color: #2ca5e0; }
  .jssocials-share-telegram .jssocials-share-link .jssocials-share-label {
    color: #2ca5e0; }
  .jssocials-share-telegram .jssocials-share-link:hover, .jssocials-share-telegram .jssocials-share-link:focus, .jssocials-share-telegram .jssocials-share-link:active {
    color: #1c88bd; }
    .jssocials-share-telegram .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-telegram .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-telegram .jssocials-share-link:active .jssocials-share-label {
      color: #1c88bd; }

.jssocials-share-line .jssocials-share-link {
  color: #25af00; }
  .jssocials-share-line .jssocials-share-link .jssocials-share-label {
    color: #25af00; }
  .jssocials-share-line .jssocials-share-link:hover, .jssocials-share-line .jssocials-share-link:focus, .jssocials-share-line .jssocials-share-link:active {
    color: #1a7c00; }
    .jssocials-share-line .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-line .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-line .jssocials-share-link:active .jssocials-share-label {
      color: #1a7c00; }

.jssocials-share-viber .jssocials-share-link {
  color: #7b519d; }
  .jssocials-share-viber .jssocials-share-link .jssocials-share-label {
    color: #7b519d; }
  .jssocials-share-viber .jssocials-share-link:hover, .jssocials-share-viber .jssocials-share-link:focus, .jssocials-share-viber .jssocials-share-link:active {
    color: #61407b; }
    .jssocials-share-viber .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-viber .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-viber .jssocials-share-link:active .jssocials-share-label {
      color: #61407b; }

.jssocials-share-pocket .jssocials-share-link {
  color: #ef4056; }
  .jssocials-share-pocket .jssocials-share-link .jssocials-share-label {
    color: #ef4056; }
  .jssocials-share-pocket .jssocials-share-link:hover, .jssocials-share-pocket .jssocials-share-link:focus, .jssocials-share-pocket .jssocials-share-link:active {
    color: #e9132e; }
    .jssocials-share-pocket .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-pocket .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-pocket .jssocials-share-link:active .jssocials-share-label {
      color: #e9132e; }

.jssocials-share-messenger .jssocials-share-link {
  color: #0084ff; }
  .jssocials-share-messenger .jssocials-share-link .jssocials-share-label {
    color: #0084ff; }
  .jssocials-share-messenger .jssocials-share-link:hover, .jssocials-share-messenger .jssocials-share-link:focus, .jssocials-share-messenger .jssocials-share-link:active {
    color: #006acc; }
    .jssocials-share-messenger .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-messenger .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-messenger .jssocials-share-link:active .jssocials-share-label {
      color: #006acc; }

.jssocials-share-vkontakte .jssocials-share-link {
  color: #45668e; }
  .jssocials-share-vkontakte .jssocials-share-link .jssocials-share-label {
    color: #45668e; }
  .jssocials-share-vkontakte .jssocials-share-link:hover, .jssocials-share-vkontakte .jssocials-share-link:focus, .jssocials-share-vkontakte .jssocials-share-link:active {
    color: #344d6c; }
    .jssocials-share-vkontakte .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-vkontakte .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-vkontakte .jssocials-share-link:active .jssocials-share-label {
      color: #344d6c; }

.jssocials-share-link {
  -webkit-transition: color 200ms ease-in-out;
          transition: color 200ms ease-in-out; }
  .jssocials-share-link.jssocials-share-link-count .jssocials-share-count {
    font-size: .5em; }

.jssocials-share-label {
  font-size: 0.8em;
  color: #444;
  -webkit-transition: color 200ms ease-in-out;
          transition: color 200ms ease-in-out; }

.jssocials-share-count-box {
  margin-left: 0.3em;
  border: 1px solid #efefef;
  border-radius: 0.3em; }
  .jssocials-share-count-box .jssocials-share-count {
    line-height: 1.8em;
    font-size: 0.8em;
    color: #444; }
